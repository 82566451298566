import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { Link } from 'react-router-dom';
import RequestForm from 'src/Components/Certificates/RequestForm';
import { HttpRequestValidation } from 'src/Components/Certificates/RequestValidation';
import UploadCertificate from 'src/Components/Certificates/UploadCertificate';

interface IProps {
    classes: any;
}
interface IState {
    activeStep: number;
    certificateId: number;
}

class CertificaatAanvraag extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            activeStep: 0,
            certificateId: 0,
        };
    }

    public render() {
        const { classes } = this.props;
        const { activeStep } = this.state;

        const steps = this.getSteps();

        return (
            <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                {this.getStepContent(index)}
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>

                {activeStep === steps.length ? (
                    <Paper square={true} elevation={0} className={classes.resetContainer}>
                        <Typography>Het certificaat is succesvol opgeslagen.</Typography>
                        <Button component={props => <Link to="/certificaten" {...props as any} />} className={classes.button}>Terug naar het overzicht</Button>
                        <Button onClick={this.handleReset} className={classes.button}>Nog een aanvragen</Button>
                    </Paper>
                ) : null}
          </div>
        );
    }

    private getSteps = () => {
        return ['Certificaat aanvragen', 'Domeinnamen verifiëren', 'Certificaat uploaden'];
    };

    private getStepContent(stepIndex: number) {
        switch (stepIndex) {
          case 0:
            return <RequestForm nextStep={this.handleNext} setCertificateId={this.setCertificateId} />;
          case 1:
            return <HttpRequestValidation certificateId={this.state.certificateId} nextStep={this.handleNext} />;
          case 2:
            return <UploadCertificate certificateId={this.state.certificateId} nextStep={this.handleNext} />;
          default:
            return 'Onbekende stap';
        }
    }

    private handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
    };

    private handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    private setCertificateId = (id: number) => {
        this.setState({
            certificateId: id,
        });
    }
}

const styles = (theme: any) => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
        padding: theme.spacing.unit * 3,
    },
});

export default withStyles(styles, { withTheme: true })(CertificaatAanvraag);

import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { CertificateApi } from 'src/ApiClients/clients';

interface IProps {
    certificateId: number;
    onClose: () => void;
    classes: any;
};
interface IState {
    open: boolean;
    showPassword: boolean;
    password: string;
    error: string | null;
};

class CertificatePasswordDialog extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            open: true,
            showPassword: false,
            password: 'loading...',
            error: null,
        };
    }

    private handleClose = () => {
        this.setState({ open: false });

        this.props.onClose();
    }

    private fetchPassword = async () => {
        try {
            const password = await CertificateApi.apiCertificatePassword(this.props.certificateId);
            this.setState({
                password: password,
            });
        } catch (err) {
            console.error(err);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het laden van het wachtwoord.',
            });
        }
    }

    private handleClickShowPassword = () => {
        this.setState(state => ({
            showPassword: !state.showPassword,
        }));

        if (this.state.password === 'loading...') {
            this.fetchPassword();
        }
    }

    public render() {
        const { open, password, showPassword, error } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
            >
                <DialogTitle>Wachtwoord</DialogTitle>
                <DialogContent>
                    <DialogContentText>Het wachtwoord van dit certificaat:</DialogContentText>
                    <FormControl>
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={this.handleClickShowPassword}>
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {error && (<FormHelperText error={true}>{error}</FormHelperText>)}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Sluiten
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = (theme: any) => ({
    passwordText: {
        marginTop: theme.spacing.unit * 2,
    }
});

export default withStyles(styles, { withTheme: true })(CertificatePasswordDialog);

import * as historyPackage from 'history';

export default class Routing {
    private static HISTORY = historyPackage.createBrowserHistory();

    /**
     * Retrieves the history object.
     */
    public static history() {
        return Routing.HISTORY;
    }

    /**
     * Navigates to a given path.
     * @param path The path to navigate to.
     * @param state The state associated with the navigation.
     */
    public static navigate(path: historyPackage.Path, state?: historyPackage.LocationState) {
        Routing.HISTORY.push(path, state);
    }
}
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import withStyles from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { CertificaatDto, UploadCertificaatDto, AppServiceDto } from 'src/ApiClients/certificateApiClient';
import { CertificateApi } from 'src/ApiClients/clients';

interface IProps {
    certificateId: number;
    nextStep: () => void;
    classes: any;
}
interface IState {
    loadingAppServices: boolean;
    availableAppServices: AppServiceDto[],
    selectedAppService: AppServiceDto | null,
    certificate: CertificaatDto,
    error: string;
    isUploading: boolean;
    isSaving: boolean;
}

class UploadCertificate extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loadingAppServices: false,
            availableAppServices: [],
            selectedAppService: null,
            certificate: new CertificaatDto(),
            error: '',
            isUploading: false,
            isSaving: false,
        };
    }

    public async componentDidMount() {
        await this.loadCertificate();
        await this.loadAppServices();
    }

    public render() {
        const { classes } = this.props;
        const { availableAppServices, selectedAppService, loadingAppServices, error, isUploading, isSaving } = this.state;

        return (
            <Paper className={classes.root}>
                <Grid container={true} spacing={24}>
                    <Grid item={true} xs={12}>
                        <p>Selecteer de app service om het certificaat aan te koppelen</p>

                        <FormControl style={{width:'100%'}} error={error !== ''}>
                            <InputLabel shrink={true} htmlFor="app-service-placeholder">App Service</InputLabel>
                            <Select
                                value={selectedAppService != null ? selectedAppService.name : ""}
                                onChange={this.onSelectAppService}
                                inputProps={{
                                    id: 'app-service-placeholder'
                                }}
                                name="appService"
                                style={{width:'100%'}}
                                disabled={(loadingAppServices && availableAppServices.length > 0) || isUploading}
                            >
                                {availableAppServices.map((appService, i) => (
                                    <MenuItem value={appService.name} key={i}>
                                        {appService.name} - {appService.deploymentSlotName}
                                        &nbsp; ({appService.resourceGroupName})
                                    </MenuItem>
                                ))}
                            </Select>
                            {loadingAppServices ? <LinearProgress className={classes.selectLoadingProgress} /> : null}
                            <FormHelperText>{error}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleUpload}
                            disabled={isUploading || isSaving || selectedAppService == null || selectedAppService.name === ''}
                            className={classes.button}
                        >
                            {isUploading ? <CircularProgress size={20} className={classes.uploadIcon} /> : <CloudUploadIcon className={classes.uploadIcon} />}
                            Uploaden
                        </Button>
                        <Button onClick={this.handleNext} disabled={isUploading || isSaving} className={classes.button}>
                            Skip
                            {isSaving && <CircularProgress size={20} className={classes.saveIcon} />}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    private onSelectAppService = (e: any) => {
        this.setState(state => ({
            selectedAppService: state.availableAppServices.filter(app => app.name === e.target.value)[0],
        }));
    }

    private handleNext = async () => {
        const uploadCertificaatDto = new UploadCertificaatDto();
        uploadCertificaatDto.shouldUploadAndBind = false;

        this.setState({ isSaving: true });

        try {
            await CertificateApi.apiCertificateUpload(this.props.certificateId, uploadCertificaatDto);
            this.setState({ isSaving: false });

            this.props.nextStep();
        } catch (err) {
            console.error(err);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het genereren van het certificaat.',
                isSaving: false,
            });
        }
    }

    private handleUpload = async () => {
        if (this.state.selectedAppService == null) {
            this.setState({ error: 'Selecteer eerst een app service om het certificaat aan te koppelen.' });
            return;
        }

        const uploadCertificaatDto = new UploadCertificaatDto();
        uploadCertificaatDto.shouldUploadAndBind = true;
        uploadCertificaatDto.resourceGroupName = this.state.selectedAppService.resourceGroupName;
        uploadCertificaatDto.appName = this.state.selectedAppService.name;
        uploadCertificaatDto.deploymentSlotName = this.state.selectedAppService.deploymentSlotName;

        this.setState({ isUploading: true });

        try {
            await CertificateApi.apiCertificateUpload(this.props.certificateId, uploadCertificaatDto);
            this.setState({ isUploading: false });

            this.props.nextStep();
        } catch (err) {
            console.error(err);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het genereren en uploaden van het certificaat.',
                isUploading: false,
            });
        }
    }

    private loadCertificate = async () => {
        try {
            const certificate = await CertificateApi.apiCertificateGet(this.props.certificateId);
            this.setState({
                certificate: certificate,
            });
        } catch (err) {
            console.error(err);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het laden van het certificaat.',
            });
        }
    }

    private loadAppServices = async () => {
        this.setState({ loadingAppServices: true });

        try {
            const appServices = await CertificateApi.apiAppserviceAll(this.state.certificate.id);

            let selectedAppService = null;
            if (appServices.length === 1) {
                selectedAppService = appServices[0];
            }

            this.setState({
                availableAppServices: appServices,
                loadingAppServices: false,
                selectedAppService: selectedAppService,
            });
        } catch (err) {
            console.error(err);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het laden van de app services.',
                loadingAppServices: false,
            });
        }
    }
}

const styles = (theme: any) => ({
    root: {
        padding: theme.spacing.unit * 2,
    },
    selectLoadingProgress: {
        height: 2,
        marginTop: -2,
    },
    uploadIcon: {
        marginRight: theme.spacing.unit,
        fontSize: 20,
    },
    saveIcon: {
        marginLeft: theme.spacing.unit,
        fontSize: 20,
    },
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
});

export default withStyles(styles, { withTheme: true })(UploadCertificate);

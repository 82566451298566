import * as React from 'react';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import { CertificaatDto } from 'src/ApiClients/certificateApiClient';
import { CertificateApi } from 'src/ApiClients/clients';

interface IProps {
    classes: any;
}

interface IState {
    certificates: CertificaatDto[];
    newDomainName: string;
    newDomainWildcard: boolean;
    isAdding: boolean;
    confirmDelete: CertificaatDto | null;
    isLoading: boolean;
    page: number;
    rowsPerPage: number;
    error: string | null;
    search: string;
}

class Certificaten extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            certificates: [],
            newDomainName: '',
            newDomainWildcard: false,
            isAdding: false,
            confirmDelete: null,
            isLoading: false,
            page: 0,
            rowsPerPage: 20,
            error: null,
            search: '',
        };
    }

    public async componentDidMount() {
        await this.reloadItems();
    }

    private reloadItems = async () => {
        this.setState({ isLoading: true });

        try {
            const certificates = await CertificateApi.apiCertificateAll();
            this.setState({ certificates: certificates, isLoading: false });
        } catch (err) {
            console.error(err);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het ophalen van de certificaten.',
                isLoading: false,
            });
        }
    }

    private onClickDelete = (certificaat: CertificaatDto) => {
        this.setState({ confirmDelete: certificaat });
    }

    private onCancelDelete = () => {
        this.setState({ confirmDelete: null });
    }

    private onConfirmedDelete = async () => {
        if (this.state.confirmDelete == null) {
            return;
        }

        const id = this.state.confirmDelete.id;
        this.setState({ confirmDelete: null });

        try {
            await CertificateApi.apiCertificateDelete(id);
        } catch (err) {
            console.log(err);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het verwijderen van het certificaat.',
            });
        }

        await this.reloadItems();
    }

    private onChangeSearch = (event: any) => {
        this.setState({
            search: event.target.value,
        });
    }

    private handleChangePage = (event: any, page: number) => {
        this.setState({
            page: page,
        });
    }

    private handleChangeRowsPerPage = (event: any) => {
        this.setState({
            rowsPerPage: event.target.value,
        });
    }

    private filterCertificaten = () => {
        const filter = this.state.search;

        return this.state.certificates
            .filter(cert => cert.domeinNaam.includes(filter));
    }

    private handleUpdateSubscriptions= async () => {
        await CertificateApi.apiSubscriptionUpdate();
    }

    public render() {
        const { classes } = this.props;
        const { confirmDelete, isLoading, error, page, rowsPerPage } = this.state;
        const certificates = this.filterCertificaten();

        const deleteDialog = confirmDelete != null && (
            <Dialog
                open={true}
                onClose={this.onCancelDelete}
            >
                <DialogTitle>Wil je dit certificaat echt verwijderen?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Als je op Verwijderen drukt zal het certificaat worden verwijderd, dit kan niet ongedaan worden gemaakt.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onCancelDelete}>
                        Annuleer
                    </Button>
                    <Button color="primary" onClick={this.onConfirmedDelete}>
                        Verwijderen
                    </Button>
                </DialogActions>
            </Dialog>
        );

        return (
            <div>
                <h2>Certificaten</h2>

                <div className={classes.actionsContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        component={props => <Link to="/certificaten/new" {...props as any} />}
                        className={classes.button}
                    >
                        Certificaat aanvragen
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleUpdateSubscriptions}
                        className={classes.button}
                    >
                        Subscriptions updaten
                    </Button>

                    <TextField
                        id="search"
                        label="Zoeken"
                        className={classes.searchField}
                        value={this.state.search}
                        onChange={this.onChangeSearch}
                    />
                </div>

                <Typography color="error">{error}</Typography>

                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Domein</TableCell>
                                <TableCell>Wildcard</TableCell>
                                <TableCell>Verloop datum</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading && (
                                <tr>
                                    <td colSpan={5} className={classes.loadingTableCell}>
                                        <LinearProgress className={classes.loadingProgress} />
                                    </td>
                                </tr>
                            )}
                            {certificates
                                .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                                .map(certificate => (
                                    <TableRow key={certificate.id} hover={true}>
                                        <TableCell>{certificate.domeinNaam}</TableCell>
                                        <TableCell>{certificate.isWildcard ? 'Ja' : 'Nee'}</TableCell>
                                        <TableCell>{moment(certificate.verloopDatum).format('DD-MM-YYYY')}</TableCell>
                                        <TableCell>{certificate.status}</TableCell>
                                        <TableCell className={classes.rowActions}>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                className={classes.actionButton}
                                                component={props => <Link to={`/certificaten/${certificate.id}`} {...props as any} />}
                                            >
                                                Bekijken
                                            </Button>

                                            <Button
                                                size="small"
                                                variant="contained"
                                                className={classes.actionButton}
                                                onClick={() => this.onClickDelete(certificate)}
                                            >
                                                Verwijderen
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}

                            {!isLoading && certificates.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Typography variant="subtitle1" align="center">Er zijn nog geen certificaten aangemaakt.</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        component="div"
                        count={certificates.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        labelRowsPerPage={'Certificaten per pagina'}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count}`}
                    />
                </Paper>
                {deleteDialog}
            </div>
        );
    }
}

const styles = (theme: any) => ({
    button: {
        marginRight: theme.spacing.unit,
    },
    actionButton: {
        marginRight: theme.spacing.unit,
    },
    rowActions: {
        textAlign: 'right' as 'right',
    },
    loadingTableCell: {
        padding: 0,
    },
    loadingProgress: {
        height: 2,
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing.unit,
    },
    searchField: {
        width: 250,
    }
});

export default withStyles(styles, { withTheme: true })(Certificaten);

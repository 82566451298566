import * as React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { ChallengeDto } from 'src/ApiClients/certificateApiClient';

interface IProps {
    challenges: ChallengeDto[];
}

function TxtRecordsTable(props: IProps) {
    const { challenges } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Domein</TableCell>
                    <TableCell>Bestandsnaam</TableCell>
                    <TableCell>Waarde</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {challenges.map((challenge, idx) => (
                    <TableRow key={idx} hover={true}>
                        <TableCell>{challenge.domein}</TableCell>
                        <TableCell>{challenge.naam}</TableCell>
                        <TableCell>{challenge.waarde}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default TxtRecordsTable;

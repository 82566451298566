import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CertificaatAanvraag from './Modules/Certificaten/Scenes/CertificaatAanvraag';
import Certificaat from './Modules/Certificaten/Scenes/Certificaat';
import Certificaten from './Modules/Certificaten/Scenes/Certificaten';
import CertificaatAanvraagAfronden from './Modules/Certificaten/Scenes/CertificaatAanvraagAfronden';
import Accounts from './Modules/Accounts/Scenes/Accounts';
import NieuwAccount from './Modules/Accounts/Scenes/NieuwAccount';
import Login from './Modules/Login/Scenes/Login';
import OidcSignInCallback from './Modules/Login/Scenes/OidcSignInCallback';

interface IProps {
    loggedIn: boolean;
}

const AppRoutes = (props: IProps) => {
    const loggedInRoutes = !props.loggedIn ? [] : [
        <Route key="certificaten" exact={true} path="/certificaten" component={Certificaten} />,
        <Route key="nieuwCertificaat" exact={true} path="/certificaten/new" component={CertificaatAanvraag} />,
        <Route key="certificaat" exact={true} path="/certificaten/:id" component={Certificaat} />,
        <Route key="certificaatAfronden" exact={true} path="/certificaten/:id/afronden" component={CertificaatAanvraagAfronden} />,
        <Route key="accounts" exact={true} path="/accounts" component={Accounts} />,
        <Route key="nieuwAccount" exact={true} path="/accounts/new" component={NieuwAccount} />
    ];

    return (
        <Switch>
            {!props.loggedIn && (
                <Route exact={true} path="/login" component={Login} />
            )}
            <Route exact={true} path="/signin-oidc" component={OidcSignInCallback} />

            {...loggedInRoutes}

            <Route>
                {props.loggedIn ? (
                    <Redirect to="/certificaten" />
                ) : (
                    <Redirect to="/login" />
                )}
            </Route>
        </Switch>
    );
}

export default AppRoutes;

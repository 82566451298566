import * as React from 'react';
import Button from '@material-ui/core/Button';
import { signin } from 'src/auth';

function Login() {
    return (
        <div>
            <h2>Login</h2>

            <Button variant="contained" color="primary" onClick={signin}>
                Log in met je JEM-id account
            </Button>
        </div>
    );
}

export default Login;

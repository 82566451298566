import * as React from 'react';
import { Link } from 'react-router-dom';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { AccountDto } from 'src/ApiClients/certificateApiClient';
import { CertificateApi } from 'src/ApiClients/clients';

interface IProps {
    classes: any;
};
interface IState {
    accounts: AccountDto[],
    isLoading: boolean;
    deleteAccount: AccountDto | null;
    error: string | null;
};

class Accounts extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            accounts: [],
            isLoading: false,
            deleteAccount: null,
            error: null,
        };
    }

    public componentDidMount() {
        this.reloadItems();
    }

    private reloadItems = async () => {
        this.setState({ isLoading: true });

        try {
            const accounts = await CertificateApi.apiAccountAll();
            this.setState({
                accounts: accounts,
                isLoading: false,
            });
        } catch (err) {
            console.error(err);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het laden van de accounts.',
                isLoading: false,
            });
        }
    }

    private onClickDelete = async (account: AccountDto) => {
        if (account.id == null) { return; }

        this.setState({ deleteAccount: account });
    }

    private onCancelDelete = () => {
        this.setState({ deleteAccount: null });
    }

    private onConfirmedDelete = async () => {
        if (this.state.deleteAccount == null || this.state.deleteAccount.id == null) { return; }

        this.setState({ deleteAccount: null });

        try {
            await CertificateApi.apiAccountDelete(this.state.deleteAccount.id);
            this.reloadItems();
        } catch (err) {
            console.error(err);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het verwijderen van het account.',
            });
        }
    }

    public render() {
        const { classes } = this.props;
        const { accounts, isLoading, deleteAccount, error } = this.state;

        const deleteDialog = deleteAccount != null && (
            <Dialog
                open={true}
                onClose={this.onCancelDelete}
            >
                <DialogTitle>Wil je dit account echt verwijderen?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Als je op Verwijderen drukt zal het account worden verwijderd, dit kan niet ongedaan worden gemaakt.
                    </DialogContentText>
                    {deleteAccount.aantalCertificaten != null && deleteAccount.aantalCertificaten > 0 && (
                        <DialogContentText>
                            <strong>Let op: </strong>
                            Dit account kan nog niet worden verwijderd omdat er bestaande certificaten aan gekoppeld zijn.
                            Verwijder eerst deze certificaten voordat je dit account verwijderd.
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onCancelDelete}>
                        Annuleer
                    </Button>
                    <Button color="primary" onClick={this.onConfirmedDelete} disabled={deleteAccount.aantalCertificaten != null && deleteAccount.aantalCertificaten > 0}>
                        Verwijderen
                    </Button>
                </DialogActions>
            </Dialog>
        );

        return (
            <div>
                <h2>Accounts</h2>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    component={props => <Link to="/accounts/new" {...props as any} />}
                >
                    Nieuw Account
                </Button>

                {error != null && (
                    <Typography color="error">{error}</Typography>
                )}

                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Naam</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Aantal gegenereerde certificaten</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading && (
                                <tr>
                                    <td colSpan={4} className={classes.loadingTableCell}>
                                        <LinearProgress className={classes.loadingProgress} />
                                    </td>
                                </tr>
                            )}
                            {accounts && accounts.map((account, i) => (
                                <TableRow key={i} hover={true}>
                                    <TableCell>{account.naam}</TableCell>
                                    <TableCell>{account.email}</TableCell>
                                    <TableCell>{account.aantalCertificaten}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            className={classes.actionButton}
                                            onClick={() => this.onClickDelete(account)}
                                        >
                                            Verwijderen
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {!isLoading && accounts.length === 0 && (
                                <TableRow hover={true}>
                                    <TableCell colSpan={4}>
                                        <Typography variant="subtitle1" align="center">Er zijn nog geen accounts aangemaakt.</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Paper>
                {deleteDialog}
            </div>
        );
    }
}

const styles = (theme: any) => ({
    button: {
        marginRight: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    actionButton: {
        marginRight: theme.spacing.unit,
    },
    loadingTableCell: {
        padding: 0,
    },
    loadingProgress: {
        height: 2,
    },
});

export default withStyles(styles, { withTheme: true })(Accounts);

import * as React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import { CertificateApi } from 'src/ApiClients/clients';
import { NieuwAccountDto, ValidationProblemDetails } from 'src/ApiClients/certificateApiClient';
import Routing from 'src/routing';

interface IProps {
    classes: any;
};
interface IState {
    naam: string;
    email: string;
    agreedToS: boolean;
    termsOfServiceUri: string;
    isSubmitting: boolean;
    errors: any;
};

class NieuwAccount extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            naam: '',
            email: '',
            agreedToS: false,
            termsOfServiceUri: '',
            isSubmitting: false,
            errors: {},
        };
    }

    public async componentDidMount() {
        const termsOfServiceUri = await CertificateApi.apiAccountTermsOfService();
        this.setState({ termsOfServiceUri: termsOfServiceUri });
    }

    private onChangeNaam = (e: any) => {
        this.setState({ naam: e.target.value });
    }

    private onChangeEmail = (e: any) => {
        this.setState({ email: e.target.value });
    }

    private onChangeAgreedToS = (e: any) => {
        this.setState(state => ({ agreedToS: !state.agreedToS }));
    }

    private handleSubmit = async (e: any) => {
        e.preventDefault();

        const model = new NieuwAccountDto();
        model.naam = this.state.naam;
        model.email = this.state.email;
        model.agreedTermsOfService = this.state.agreedToS;

        this.setState({ isSubmitting: true });

        try {
            await CertificateApi.apiAccountPost(model);

            Routing.navigate('/accounts');
        } catch (error) {
            if (!(error instanceof ValidationProblemDetails)) {
                throw error;
            }

            const errors = {};

            if (error.errors != null) {
                for (let key in error.errors) {
                    if (error.errors.hasOwnProperty(key)) {
                        errors[key] = error.errors[key];
                    }
                }
            }

            this.setState({
                errors: errors,
            });
        } finally {
            this.setState({ isSubmitting: false });
        }
    }

    public render() {
        const { classes } = this.props;
        const { email, naam, agreedToS, termsOfServiceUri, isSubmitting, errors } = this.state;

        return (
            <div>
                <Paper className={classes.root}>
                    <form onSubmit={this.handleSubmit}>
                        <Grid container={true} spacing={24}>
                            <Grid item={true} xs={12}>
                                <Typography variant="h5">Nieuw Account</Typography>
                            </Grid>
                            {(errors[''] || errors.AgreedTermsOfService) && (
                                <Grid item={true} xs={12}>
                                    <Typography color="error" component={props => <pre {...props} />}>
                                        {(errors[''] || []).concat(errors.AgreedTermsOfService || []).join('\n')}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item={true} xs={12}>
                                <TextField
                                    id="naam"
                                    label="Naam"
                                    value={naam}
                                    onChange={this.onChangeNaam}
                                    fullWidth={true}
                                    error={errors.Naam}
                                    helperText={errors.Naam && errors.Naam.join(', ')}
                                />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <TextField
                                    id="email"
                                    label="Email"
                                    value={email}
                                    onChange={this.onChangeEmail}
                                    fullWidth={true}
                                    error={errors.Email}
                                    helperText={errors.Email && errors.Email.join(', ')}
                                />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox id="agreedToS" name="agreedToS" onChange={this.onChangeAgreedToS} checked={agreedToS} />
                                    }
                                    label={
                                        <FormLabel htmlFor="agreedToS">Ik ga akkoord met the <a href={termsOfServiceUri} target="_blank">Terms of Service</a></FormLabel>
                                    }
                                />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>Aanmaken</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>

                <Button component={props => <Link to="/accounts" {...props as any} />}>Terug</Button>
            </div>
        );
    }
}

const styles = (theme: any) => ({
    root: {
        padding: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
});

export default withStyles(styles, { withTheme: true })(NieuwAccount);

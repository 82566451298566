import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Routing from 'src/routing';
import { processSigninCallback } from 'src/auth';

class OidcSignInCallback extends React.Component {
    constructor(props: RouteComponentProps<any>) {
        super(props);

        console.log(window.location);
        processSigninCallback().then(() => {
            Routing.navigate('/');
        });
    }

    public render() {
        return (
            <div>
                <p>Redirecting...</p>
            </div>
        );
    }
}

export default OidcSignInCallback;

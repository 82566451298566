import { library } from '@fortawesome/fontawesome-svg-core';
import { faClipboard, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { CertificateApi } from 'src/ApiClients/clients';
import { NieuwCertificaatDto, ValidationProblemDetails, AccountDto, SubscriptionDto } from 'src/ApiClients/certificateApiClient';

interface IProps {
    classes: any;
    nextStep: () => void;
    setCertificateId: (id: number) => void;
}

interface IState {
    isWildcard: boolean;
    domainName: string;
    isSubmitting: boolean;
    error: string;
    accounts: AccountDto[];
    selectedAccount: AccountDto | null;
    loadingAccounts: boolean;
    subscriptions: SubscriptionDto[];
    selectedSubscription: SubscriptionDto | null;
    loadingSubscriptions: boolean;
}

class RequestForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isWildcard: false,
            domainName: '',
            isSubmitting: false,
            error: '',
            accounts: [],
            selectedAccount: null,
            loadingAccounts: false,
            subscriptions: [],
            selectedSubscription: null,
            loadingSubscriptions: false
        };

        library.add({ faClipboard, faSync });
    }

    public async componentDidMount() {
        this.getAccounts();
        this.getSubscriptions();      
    }

    private getAccounts = async () => {
        try {
            this.setState({ loadingAccounts: true });
            const accounts = await CertificateApi.apiAccountAll();
            this.setState({
                accounts: accounts,
                loadingAccounts: false,
            });
        } catch (err) {
            console.error(err);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het ophalen van de accounts',
                loadingAccounts: false,
            });
        }
    }

    private getSubscriptions = async () => {
        try {
            this.setState({ loadingSubscriptions: true });
            const subscriptions = await CertificateApi.apiSubscriptionAll();
            this.setState({
                subscriptions: subscriptions,
                loadingSubscriptions: false,
            });
        } catch (err) {
            console.error(err);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het ophalen van de subscriptions',
                loadingSubscriptions: false,
            });
        }
    }

    private onChangeDomainName = (e: any) => {
        this.setState({
            domainName: e.target.value
        });
    }

    private onChangeIsWildcard = (e: any) => {
        this.setState({
            isWildcard: !this.state.isWildcard
        });
    }

    private onSelectAccount = (e: any) => {
        const account = this.state.accounts.find(x => x.naam === e.target.value);

        if (account != null) {
            this.setState({
                selectedAccount: account,
            });
        }
    }

    private onSelectSubscription = (e: any) => {
        const subscription = this.state.subscriptions.find(x => x.naam === e.target.value);

        if (subscription != null) {
            this.setState({
                selectedSubscription: subscription,
            });
        }
    }

    private submitDomainName = async (e: any) => {
        e.preventDefault();

        if (this.state.selectedAccount == null) {
            this.setState({ error: 'Selecteer een account waar dit certificaat mee wordt aangevraagd.' });
            return;
        }

        if (this.state.selectedSubscription == null) {
            this.setState({ error: 'Selecteer een subscription waar dit certificaat mee wordt aangevraagd.' });
            return;
        }

        const certificaatDto = new NieuwCertificaatDto();
        certificaatDto.domeinNaam = this.state.domainName;
        certificaatDto.isWildcard = this.state.isWildcard;
        certificaatDto.accountId = this.state.selectedAccount.id!!;
        certificaatDto.subscriptionId = this.state.selectedSubscription.id!!;

        this.setState({ isSubmitting: true });

        try {
            const id = await CertificateApi.apiCertificatePost(certificaatDto)

            this.props.setCertificateId(id);
            this.props.nextStep();
        } catch (problem) {
            if (!(problem instanceof ValidationProblemDetails)) {
                throw problem;
            }

            console.error(problem);
            console.dir(problem);

            let errors: string[] = [];

            if (problem.errors != null) {
                for (let key in problem.errors) {
                    if (problem.errors.hasOwnProperty(key)) {
                        errors = errors.concat(problem.errors[key]);
                    }
                }
            }

            this.setState({
                error: errors.join('\n'),
            });
        } finally {
            this.setState({ isSubmitting: false });
        }
    }

    public render() {
        const { classes } = this.props;
        const { domainName, isWildcard, isSubmitting, error, accounts, selectedAccount, loadingAccounts, subscriptions, selectedSubscription, loadingSubscriptions } = this.state;

        return (
            <div>
                <Paper className={classes.root}>
                    <form onSubmit={this.submitDomainName}>
                        <Grid container={true} spacing={24}>
                            {error != null && error !== '' && (
                                <Grid item={true} xs={12}>
                                    <Typography color="error">{error}</Typography>
                                </Grid>
                            )}
                            <Grid item={true} xs={12}>
                                <TextField
                                    id="domainName"
                                    label="Domeinnamen"
                                    value={domainName}
                                    onChange={this.onChangeDomainName}
                                    fullWidth={true}
                                    helperText="Vul meerdere domeinnamen in door ze te scheiden met een spatie"
                                />

                                <FormControlLabel
                                    control={ <Checkbox name="isWildcard" onChange={this.onChangeIsWildcard} checked={isWildcard} /> }
                                    label="Wildcard certificaat"
                                    style={{display:'none'}} // Hide the isWildcard toggle because dns verification is required for wildcard certificates
                                />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <FormControl style={{width:'100%'}}>
                                    <InputLabel shrink={true} htmlFor="account-placeholder">Account</InputLabel>
                                    <Select
                                        value={selectedAccount != null ? selectedAccount.naam : ""}
                                        onChange={this.onSelectAccount}
                                        inputProps={{
                                            id: 'account-placeholder'
                                        }}
                                        name="account"
                                        style={{width:'100%'}}
                                        disabled={(loadingAccounts && accounts.length > 0) || isSubmitting}
                                    >
                                        {accounts.map((account) => (
                                            <MenuItem value={account.naam} key={account.id}>
                                                {account.naam}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {loadingAccounts ? <LinearProgress className={classes.selectLoadingProgress} /> : null}
                                </FormControl>
                            </Grid>

                            <Grid item={true} xs={12}>
                                <FormControl style={{width:'100%'}}>
                                    <InputLabel shrink={true} htmlFor="subscription-placeholder">Subscription</InputLabel>
                                    <Select
                                        value={selectedSubscription != null ? selectedSubscription.naam : ""}
                                        onChange={this.onSelectSubscription}
                                        inputProps={{
                                            id: 'subscription-placeholder'
                                        }}
                                        name="subscription"
                                        style={{width:'100%'}}
                                        disabled={(loadingSubscriptions && subscriptions.length > 0) || isSubmitting}
                                    >
                                        {subscriptions.map((subscription) => (
                                            <MenuItem value={subscription.naam} key={subscription.id}>
                                                {subscription.naam}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {loadingAccounts ? <LinearProgress className={classes.selectLoadingProgress} /> : null}
                                </FormControl>
                            </Grid>

                            <Grid item={true} xs={12}>
                                <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                                    <FontAwesomeIcon icon={faSync} spin={isSubmitting} style={{marginRight: 8}} />
                                    Aanvragen
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </div>
        );
    };
};

const styles = (theme: any) => ({
    root: {
        padding: theme.spacing.unit * 2,
    },
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    selectLoadingProgress: {
        height: 2,
        marginTop: -2,
    },
});

export default withStyles(styles, { withTheme: true })(RequestForm);

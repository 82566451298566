import Button from '@material-ui/core/Button';
import * as React from 'react';
import { CertificateApi } from 'src/ApiClients/clients';
import { CertificaatDto, VerifyCertificaatDto } from 'src/ApiClients/certificateApiClient';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import TxtRecordsTable from 'src/Components/TxtRecordsTable';

interface IProps {
    certificateId: number;
    nextStep: () => void;
    classes: any;
}
interface IState {
    certificate: CertificaatDto;
    isVerifying: boolean;
    error: string;
}

class RequestValidation extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            certificate: new CertificaatDto(),
            isVerifying: false,
            error: '',
        };
    }

    public async componentDidMount() {
        await this.loadDetails();
    }

    public render() {
        const { classes } = this.props;
        const { certificate, isVerifying, error } = this.state;

        return (
            <Paper className={classes.root}>
                <Grid container={true} spacing={24}>
                    <Grid item={true} xs={12}>
                        <Typography variant="headline" component="h2">
                            {certificate.domeinNaam}
                        </Typography>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <p>Zorg ervoor dat de volgende DNS records ingesteld staan: </p>

                        <Typography color="error">{error}</Typography>
                    </Grid>

                    <Grid item={true} xs={12}>
                        {certificate.challenges && <TxtRecordsTable records={certificate.challenges.filter(x => x.type === 'dns')} />}
                    </Grid>

                    <Grid item={true} xs={12}>
                        <Button variant="contained" color="primary" onClick={this.onClickValidation} disabled={isVerifying}>
                            {isVerifying ? (
                                <CircularProgress size={20} className={classes.buttonCenterProgress} />
                            ) : "Valideren"}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    private loadDetails = async () => {
        const result = await CertificateApi.apiCertificateGet(this.props.certificateId)

        console.log(result);
        this.setState({
            certificate: result,
        });
    }

    private onClickValidation = async () => {
        this.setState({ isVerifying: true });

        try {
            const model = new VerifyCertificaatDto();
            const lookupSucces = await CertificateApi.apiCertificateLookup(this.props.certificateId, model);

            if (!lookupSucces) {
                this.setState({
                    error: 'Nog niet alle DNS records konden gevonden worden.'
                });
                return;
            }

            const verified = await CertificateApi.apiCertificateVerify(this.props.certificateId);
            this.setState({ isVerifying: false });

            if (verified) {
                this.props.nextStep();
            } else {
                this.setState({
                    error: 'Nog niet alle DNS records konden gevonden worden.'
                });
                console.error('Invalid');
            }
        } catch (error) {
            console.error(error);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het valideren',
            });
        } finally {
            this.setState({
                isVerifying: false,
            });
        }
    }
}

const styles = (theme: any) => ({
    root: {
        padding: theme.spacing.unit * 2,
    },
    buttonCenterProgress: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

export default withStyles(styles, { withTheme: true })(RequestValidation);

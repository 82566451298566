import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { CertificaatDto, ProblemDetails } from 'src/ApiClients/certificateApiClient';
import { CertificateApi } from 'src/ApiClients/clients';
import Routing from 'src/routing';
import { CloudDownload as DownloadIcon } from '@material-ui/icons';
import CertificatePasswordDialog from 'src/Components/CertificatePasswordDialog';
import HttpChallengesTable from 'src/Components/HttpChallengesTable';
import TxtRecordsTable from 'src/Components/TxtRecordsTable';
import { API_BASE_URL } from 'src/env';

interface ICertificaatProps { id: string; }
interface IProps extends RouteComponentProps<ICertificaatProps> {
    classes: any;
}

interface IState {
    certificateId: number;
    certificate: CertificaatDto;
    isLookingup: boolean;
    lookupSuccess: boolean;
    isVerifying: boolean;
    verifySuccess: boolean;
    showPassword: boolean;
    error: string | null;
}

class Certificaat extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            certificateId: parseInt(props.match.params.id, undefined),
            certificate: new CertificaatDto(),
            isLookingup: false,
            lookupSuccess: false,
            isVerifying: false,
            verifySuccess: false,
            showPassword: false,
            error: null,
        };
    }

    public async componentDidMount() {
        await this.reloadItems();
    }

    private reloadItems = async () => {
        try {
            let certificate = await CertificateApi.apiCertificateGet(this.state.certificateId);
            this.setState({ certificate: certificate });
        } catch (error) {
            if (error instanceof ProblemDetails && error.status === 404) {
                Routing.navigate("/certificaten");
            } else {
                console.error(error);
                this.setState({
                    error: 'Er is een fout opgetreden tijdens het ophalen van het certificaat.',
                });
            }
        }
    }

    private onClickBack = () => {
        Routing.navigate('/certificaten');
    }

    private onClickDownload = () => {
        location.href = `${API_BASE_URL}/api/certificate/${this.state.certificateId}/download`;
    }

    private onClickShowPassword = () => {
        this.setState({ showPassword: true });
    }

    private onClosePasswordDialog = () => {
        this.setState({ showPassword: false });
    }

    public render() {
        const { classes } = this.props;
        const { certificate, showPassword } = this.state;

        if (certificate.id == null) {
            return (<div>Loading...</div>);
        }

        const httpChallenges = certificate.challenges.filter(x => x.type === 'http');
        const dnsChallenges = certificate.challenges.filter(x => x.type === 'dns');

        const isGegenereerd = certificate.status === 'Gegenereerd' || certificate.status === 'Actief';

        return (
            <div>
                <h2>
                    {certificate.domeinNaam}
                </h2>

                {certificate.status !== 'Actief' && (
                    <Paper className={classes.paper}>
                        <div className={classes.flexContainer}>
                            <Typography>
                                <strong>Let op:</strong> &nbsp;
                                Dit certificaat is nog niet geupload en kan niet automatisch vernieuwt worden.
                            </Typography>

                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                component={props => <Link to={`/certificaten/${certificate.id}/afronden`} {...props as any} />}
                            >
                                Ga verder
                            </Button>
                        </div>
                    </Paper>
                )}

                {httpChallenges.length > 0 && (
                    <>
                        <Typography variant="h5" className={classes.title}>HTTP Challenges</Typography>
                        <Paper className={classes.paper}>
                            <HttpChallengesTable challenges={httpChallenges} />
                        </Paper>
                    </>
                )}
                {dnsChallenges.length > 0 && (
                    <>
                        <Typography variant="h5" className={classes.title}>DNS records</Typography>
                        <Paper className={classes.paper}>
                            <TxtRecordsTable records={dnsChallenges} />
                        </Paper>
                    </>
                )}

                <Button variant="text" onClick={this.onClickBack} className={classes.button}>Terug</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.onClickDownload}
                    className={classes.button}
                    disabled={!isGegenereerd}
                >
                    <DownloadIcon className={classes.iconLeft} />
                    Download certificaat
                </Button>
                <Button variant="text" onClick={this.onClickShowPassword} className={classes.button} disabled={!isGegenereerd}>Bekijk wachtwoord</Button>

                {showPassword && <CertificatePasswordDialog certificateId={certificate.id} onClose={this.onClosePasswordDialog} />}
            </div>
        );
    }
}

const styles = (theme: any) => ({
    paper: {
        marginBottom: theme.spacing.unit * 2,
    },
    button: {
        marginBottom: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    iconLeft: {
        marginRight: theme.spacing.unit,
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing.unit * 2,
    },
    title: {
        marginBottom: theme.spacing.unit * 2,
    },
});

export default withStyles(styles, { withTheme: true })(Certificaat);

const isProduction = process.env.NODE_ENV === 'production';

let API_BASE_URL: string = 'http://localhost:64396';
let REDIRECT_URI_BASE: string = 'http://localhost:3000/';
let GOOGLE_CLIENT_ID: string = '197827196619-gd9gh6pnnveq8t4i55e2crdq15bg7j25.apps.googleusercontent.com';

if (isProduction) {
    API_BASE_URL = 'https://certificaten-api.jem-id.nl';
    REDIRECT_URI_BASE = 'https://certificaten-portal.jem-id.nl/';
}

export {
    API_BASE_URL,
    REDIRECT_URI_BASE,
    GOOGLE_CLIENT_ID,
};

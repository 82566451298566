import Button from '@material-ui/core/Button';
import * as React from 'react';
import { CertificateApi } from 'src/ApiClients/clients';
import { CertificaatDto, AppServiceDto, VerifyCertificaatDto, ValidationProblemDetails } from 'src/ApiClients/certificateApiClient';
import { Typography } from '@material-ui/core';
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import CheckIcon from '@material-ui/icons/Check';

interface IProps {
    certificateId: number;
    nextStep: () => void;
    classes: any;
}
interface IState {
    certificate: CertificaatDto;
    isVerifying: boolean;
    error: string;

    selectedAppService: AppServiceDto | null,
    availableAppServices: AppServiceDto[],
    loadingAppServices: boolean;
}

class RequestValidation extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            certificate: new CertificaatDto(),
            isVerifying: false,
            error: '',
            selectedAppService: null,
            availableAppServices: [],
            loadingAppServices: false,
        };
    }

    public async componentDidMount() {
        await this.loadDetails();
        await this.loadAppServices();
    }

    public render() {
        const { classes } = this.props;
        const { certificate, isVerifying, error, selectedAppService, availableAppServices, loadingAppServices } = this.state;

        return (
            <Paper className={classes.root}>
                <Grid container={true} spacing={24}>
                    <Grid item={true} xs={12}>
                        <Typography variant="headline" component="h2">
                            {certificate.domeinNaam}
                        </Typography>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <p>Selecteer de app service waar de bestanden naar geupload moeten worden: </p>

                        <Typography color="error">{error}</Typography>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel shrink={true} htmlFor="app-service-placeholder">App Service</InputLabel>
                            <Select
                                value={selectedAppService != null ? selectedAppService.name : ""}
                                onChange={this.onSelectAppService}
                                inputProps={{
                                    id: 'app-service-placeholder'
                                }}
                                name="appService"
                                style={{ width: '100%' }}
                                disabled={(loadingAppServices && availableAppServices.length > 0) || isVerifying}
                            >
                                {availableAppServices.map((appService, i) => (
                                    <MenuItem value={appService.name} key={i}>
                                        {appService.name} - {appService.deploymentSlotName}
                                        &nbsp; ({appService.resourceGroupName})
                                    </MenuItem>
                                ))}
                            </Select>
                            {loadingAppServices ? <LinearProgress className={classes.selectLoadingProgress} /> : null}
                        </FormControl>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <Button variant="contained" color="primary" onClick={this.onClickValidation} disabled={isVerifying}>
                            {isVerifying ? <CircularProgress size={20} className={classes.checkIcon} /> : <CheckIcon className={classes.checkIcon} />}
                            Valideren
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    private loadDetails = async () => {
        try {
            const result = await CertificateApi.apiCertificateGet(this.props.certificateId)

            this.setState({
                certificate: result,
            });
        } catch (err) {
            console.error(err);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het laden van het certificaat.',
            });
        }
    }

    private loadAppServices = async () => {
        this.setState({ loadingAppServices: true });

        try {
            const appServices = await CertificateApi.apiAppserviceAll(this.state.certificate.id);

            let selectedAppService = null;
            if (appServices.length === 1) {
                selectedAppService = appServices[0];
            }

            this.setState({
                availableAppServices: appServices,
                loadingAppServices: false,
                selectedAppService: selectedAppService,
            });
        } catch (err) {
            console.error(err);
            this.setState({
                error: 'Er is een fout opgetreden tijdens het laden van de app services.',
                loadingAppServices: false,
            });
        }
    }

    private onSelectAppService = (e: any) => {
        this.setState(state => ({
            selectedAppService: state.availableAppServices.filter(app => app.name === e.target.value)[0],
        }));
    }

    private onClickValidation = async () => {
        const appService = this.state.selectedAppService;
        if (appService == null) {
            return;
        }

        this.setState({ isVerifying: true });

        try {
            const model = new VerifyCertificaatDto();
            model.resourceGroupNaam = appService.resourceGroupName;
            model.appServiceNaam = appService.name;
            model.deploymentSlotNaam = appService.deploymentSlotName;
            const lookupSucces = await CertificateApi.apiCertificateLookup(this.props.certificateId, model);

            if (!lookupSucces) {
                this.setState({
                    error: 'Validatie was niet succesvol, niet alle challenges waren gevonden.'
                });
                return;
            }

            const verified = await CertificateApi.apiCertificateVerify(this.props.certificateId);
            this.setState({ isVerifying: false });

            if (verified) {
                this.props.nextStep();
            } else {
                this.setState({
                    error: 'Verificatie was niet succesvol, niet alle challenges waren gevonden.'
                });
                console.error('Invalid');
            }
        } catch (error) {
            if (error instanceof ValidationProblemDetails && error.errors != null && error.errors.validatie != null) {
                this.setState({
                    error: error.errors.validatie[0]
                });
            } else {
                console.error(error);
                this.setState({
                    error: 'Er is een onbekende fout opgetreden tijdens het valideren.',
                });
            }
        } finally {
            this.setState({
                isVerifying: false,
            });
        }
    }
}

const styles = (theme: any) => ({
    root: {
        padding: theme.spacing.unit * 2,
    },
    selectLoadingProgress: {
        height: 2,
        marginTop: -2,
    },
    checkIcon: {
        marginRight: theme.spacing.unit,
        fontSize: 20,
    },
});

export default withStyles(styles, { withTheme: true })(RequestValidation);

import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, StyleRulesCallback, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import Navigation from './Components/Navigation';
import * as classNames from 'classnames';
import { MenuItem, Menu } from '@material-ui/core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '@material-ui/core/Avatar';
import { Router } from 'react-router-dom';

import './App.css';
import { faUserCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import AppRoutes from './AppRoutes';
import Routing from './routing';
import { isLoggedIn, getUserInfo, clientLogout, events, IUserInfo } from './auth';

library.add({ faUserCircle, faSignOutAlt })

interface IProps {
  classes: any,
  theme: any
}

interface IState {
  open: boolean;
  openMenuNav: boolean;
  auth: boolean;
  user: IUserInfo | null;
  anchorEl: HTMLElement | null;
  loading: boolean;
}

const drawerWidth = 240;
const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#c3cf21',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
  avatar: {
    position: 'absolute',
    right: theme.spacing.unit * 3,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  grow: {
    flexGrow: 1,
  },
  iconLeft: {
    marginRight: theme.spacing.unit,
  },
});

class App extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      open: false,
      openMenuNav: false,
      auth: false,
      user: null,
      anchorEl: null,
      loading: true,
    }
  }

  public async componentDidMount() {
    events.addUserLoaded(this.refreshUserState);
    events.addUserUnloaded(this.refreshUserState);
    events.addUserSignedOut(this.refreshUserState);

    await this.refreshUserState();
    this.setState({ loading: false });
  }

  public componentWillUnmount() {
    events.removeUserLoaded(this.refreshUserState);
    events.removeUserUnloaded(this.refreshUserState);
    events.removeUserSignedOut(this.refreshUserState);
  }

  private refreshUserState = async () => {
    this.setState({
      auth: await isLoggedIn(),
      user: await getUserInfo(),
    });
  }

  public render() {
    const { classes, theme } = this.props;
    const { user, loading } = this.state;

    const profilePicture: string = user == null ? "" : Array.isArray(user.picture) ? user.picture[0] : user.picture;

    return (
      <Router history={Routing.history()}>
        <div className="App">
          <div className={classes.root}>
            <AppBar position="fixed" className={classNames(classes.appBar, this.state.open && classes.appBarShift)}>
              <Toolbar disableGutters={!this.state.open}>
                <IconButton color="inherit" aria-label="Open drawer" onClick={this.handleDrawerOpen} className={classNames(classes.menuButton, this.state.open && classes.hide)}>
                  <MenuIcon />
                </IconButton>

                <Typography variant="h6" color="inherit" noWrap={true} className={classes.grow}>
                  JEM-id Certificates
                </Typography>

                {user && (
                  <>
                    <Avatar className={classes.avatar} src={profilePicture} onClick={this.handleMenu} title={user.name} />

                    <Menu
                      id="menu-appbar"
                      open={this.state.openMenuNav}
                      anchorEl={this.state.anchorEl}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      onClose={this.handleClose}
                    >
                      <MenuItem onClick={this.handleLogout}>
                        <FontAwesomeIcon icon="sign-out-alt" className={classes.iconLeft} /> Uitloggen
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Toolbar>
            </AppBar>

            <Drawer
              variant="permanent"
              className={classNames(classes.drawer, {
                [classes.drawerOpen]: this.state.open,
                [classes.drawerClose]: !this.state.open
              })}
              classes={{
                paper: classNames({
                  [classes.drawerOpen]: this.state.open,
                  [classes.drawerClose]: !this.state.open
                })
              }}
              open={this.state.open}
            >
              <div className={classes.toolbar}>
                <IconButton onClick={this.handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </div>
              <Divider />
              <Navigation />
            </Drawer>

            <main className={classes.content}>
              <div className={classes.toolbar} />
              {!loading && (
                <AppRoutes loggedIn={this.state.auth} />
              )}
            </main>
          </div>
        </div>
      </Router>
    );
  }

  private handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  private handleDrawerClose = () => {
    this.setState({ open: false });
  };

  private handleMenu = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
      openMenuNav: true
    });
  };

  private handleClose = () => {
    this.setState({
      anchorEl: null,
      openMenuNav: false
    });
  };

  private handleLogout = async () => {
    await clientLogout();

    this.setState({
      user: null,
      auth: false,
    });

    this.handleClose();
  }
}

export default withStyles(styles, { withTheme: true })(App);

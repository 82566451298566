import * as React from 'react';
import { Link } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCertificate } from '@fortawesome/free-solid-svg-icons';

library.add({ faUserCircle, faCertificate })

interface IProps extends RouteComponentProps {
  classes: any;
}
interface IState { }

class Navigation extends React.Component<IProps, IState> {
  private activeRoute = (routeName: string) => {
    return this.props.location.pathname.indexOf(routeName) > -1;
  }

  public render() {
    const { classes } = this.props;

    return (
      <MenuList>
        <Link to="/accounts" className={classes.menuLink}>
          <MenuItem selected={this.activeRoute("/accounts")}>
            <ListItemIcon>
              <FontAwesomeIcon icon="user-circle" className={classes.drawerListIcon} />
            </ListItemIcon>
            <ListItemText primary="Accounts" />
          </MenuItem>
        </Link>
        <Link to="/certificaten" className={classes.menuLink}>
          <MenuItem selected={this.activeRoute("/certificaten")}>
            <ListItemIcon>
              <FontAwesomeIcon icon="certificate" className={classes.drawerListIcon} />
            </ListItemIcon>
            <ListItemText primary="Certificaten" />
          </MenuItem>
        </Link>
      </MenuList>
    )
  }
}

const styles = {
  menuLink: {
    textDecoration: 'none',
  },
  drawerListIcon: {
    width: '18px !important',
    height: 18,
    padding: 3
  }
};

export default withStyles(styles)(withRouter(Navigation));

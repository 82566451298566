import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { HttpRequestValidation } from 'src/Components/Certificates/RequestValidation';
import UploadCertificate from 'src/Components/Certificates/UploadCertificate';
import { CertificateApi } from 'src/ApiClients/clients';
import { CertificaatDto } from 'src/ApiClients/certificateApiClient';

interface ICertificaatProps {
    id: string;
}
interface IProps extends RouteComponentProps<ICertificaatProps> {
    classes: any;
};
interface IState {
    activeStep: number;
    certificateId: number;
    certificate: CertificaatDto;
    loading: boolean;
};

class CertificaatAanvraagAfronden extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            activeStep: -1,
            certificateId: parseInt(props.match.params.id, 10),
            certificate: new CertificaatDto(),
            loading: false,
        };
    }

    public async componentDidMount() {
        this.setState({ loading: true });
        const certificate = await CertificateApi.apiCertificateGet(this.state.certificateId);

        const activeStep = certificate.status === 'Aangevraagd' ? 1
            : certificate.status === 'Geverifieerd' || certificate.status === 'Gegenereerd' ? 2
            : certificate.status === 'Actief' ? 3
            : 0;

        this.setState({
            certificate: certificate,
            activeStep: activeStep,
            loading: false,
        });
    }

    private getSteps = () => {
        return ['Certificaat aanvragen', 'Domeinnamen verifiëren', 'Certificaat uploaden'];
    };

    private getStepContent(stepIndex: number) {
        switch (stepIndex) {
          case 0:
            return <Redirect to="/certificaten/new" />;
          case 1:
            return <HttpRequestValidation certificateId={this.state.certificateId} nextStep={this.handleNext} />;
          case 2:
            return <UploadCertificate certificateId={this.state.certificateId} nextStep={this.handleNext} />;
          default:
            return 'Onbekende stap';
        }
    }

    private handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
    };

    public render() {
        const { classes } = this.props;
        const { activeStep } = this.state;

        const steps = this.getSteps();

        return (
            <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                {this.getStepContent(index)}
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>

                {activeStep === steps.length ? (
                    <Paper square={true} elevation={0} className={classes.resetContainer}>
                        <Typography>Het certificaat is succesvol opgeslagen.</Typography>
                        <Button component={props => <Link to="/certificaten" {...props as any} />} className={classes.button}>Terug naar het overzicht</Button>
                    </Paper>
                ) : null}
            </div>
        );
    }
}

const styles = (theme: any) => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
        padding: theme.spacing.unit * 3,
    },
});

export default withStyles(styles, { withTheme: true })(CertificaatAanvraagAfronden);

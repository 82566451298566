import { UserManager, UserManagerSettings, Log } from 'oidc-client';
import { GOOGLE_CLIENT_ID, REDIRECT_URI_BASE } from 'src/env';

Log.logger = console;

const userManagerSettings: UserManagerSettings = {
    authority: 'https://accounts.google.com',
    client_id: GOOGLE_CLIENT_ID,
    redirect_uri: `${REDIRECT_URI_BASE}signin-oidc`,
    response_type: 'id_token token',
    scope: 'openid profile email',
    post_logout_redirect_uri: REDIRECT_URI_BASE,
    silent_redirect_uri: `${REDIRECT_URI_BASE}signin-oidc`,
    automaticSilentRenew: true,
    extraQueryParams: {
        hd: 'jem-id.nl',
    },
};

const userManager = new UserManager(userManagerSettings);
userManager.clearStaleState();

(window as any).userManager = userManager;

export const events = userManager.events;

events.addAccessTokenExpiring(refreshAccessToken);
events.addAccessTokenExpired(refreshAccessToken);

export interface IUserInfo {
    email: string;
    name: string;
    picture: string[];
    given_name: string;
    family_name: string;
    locale: string;
}

export function signin(): void {
    userManager.signinRedirect({
        prompt: 'select_account',
    });
}

export function processSigninCallback(): Promise<any> {
    return userManager.signinRedirectCallback();
}

export function clientLogout(): Promise<void> {
    return userManager.removeUser();
}

export async function getUserInfo(): Promise<IUserInfo | null> {
    const user = await userManager.getUser();

    if (user == null) {
        return null;
    }

    return user.profile;
}

export async function isLoggedIn(): Promise<boolean> {
    const user = await userManager.getUser();

    return user != null;
}

export async function getAccessToken(): Promise<string | null> {
    const user = await userManager.getUser();

    if (user == null) {
        return null;
    }

    return user.id_token;
}

export async function refreshAccessToken(): Promise<void> {
    const user = await userManager.getUser();
    const sub = user != null ? user.profile.sub : null;

    try {
        await userManager.signinPopup({
            prompt: 'none',
            extraQueryParams: {
                hd: 'jem-id.nl',
                login_hint: sub,
            },
        });
    } catch (err) {
        console.error(err);
        await userManager.removeUser();
    }
}

import { getAccessToken, refreshAccessToken } from 'src/auth';

export default abstract class BearerTokenApiClient {
    protected async transformOptions(options: RequestInit): Promise<RequestInit> {
        const jwt = await getAccessToken();

        if (!options.headers) {
            options.headers = {};
        }

        // tslint:disable-next-line:no-string-literal
        options.headers['Authorization'] = 'Bearer ' + jwt;

        return options;
    }

    protected transformResult(url: string, response: Response, processor: (response: Response) => any) {
        if (response.status === 401) {
            this.handleUnauthorizedResponse();
            return null;
        }

        return processor(response);
    }

    private handleUnauthorizedResponse() {
        refreshAccessToken();
    }
}

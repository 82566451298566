import * as React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FileCopy from '@material-ui/icons/FileCopy';
import * as CopyToClipboard from 'react-copy-to-clipboard';
import { ChallengeDto } from 'src/ApiClients/certificateApiClient';

interface IProps {
    records: ChallengeDto[];
}

function TxtRecordsTable(props: IProps) {
    const { records } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>DNS sleutel</TableCell>
                    <TableCell>Waarde</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Tijd</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {records.map((record, idx) => (
                    <TableRow key={idx} hover={true}>
                        <TableCell>
                            {record.naam}

                            <CopyToClipboard text={record.naam || ""}>
                                <IconButton color="primary">
                                    <FileCopy />
                                </IconButton>
                            </CopyToClipboard>
                        </TableCell>
                        <TableCell>
                            {record.waarde}

                            <CopyToClipboard text={record.waarde || ""}>
                                <IconButton color="primary">
                                    <FileCopy />
                                </IconButton>
                            </CopyToClipboard>
                        </TableCell>
                        <TableCell>TXT</TableCell>
                        <TableCell>5 min</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default TxtRecordsTable;
